<template>
  <tasks-table
    :options="options"
  />
</template>

<script>
// Components
import TasksTable from '../../components/tables/TasksTable.vue'

export default {
  name: 'TasksTasks',
  components: {
    TasksTable
  },
  computed: {
    options () {
      return {
        title: this.$t('Tasks'),
        service: this.$service.pickingTask,
        queue: this.$service.pickingQueue,
        fields: ['reserve', 'sequence', 'shop', 'productOffer', 'place', 'item']
      }
    }
  }
}
</script>
