<template>
  <q-dialog v-model="isOpen">
    <q-card style="min-width: 40vw; overflow: visible;">
      <q-card-section class="row">
        <div class="text-h6 text-center">
          {{ title }}
        </div>

        <q-space />

        <q-btn
          color="transparent"
          text-color="dark"
          size="sm"
          icon="close"
          no-caps
          unelevated
          @click="close"
        />
      </q-card-section>

      <q-card-section class="text-center">
        <form-builder :schema="schema" />
      </q-card-section>

      <q-card-section class="text-center">
        <q-btn
          color="dark"
          text-color="white"
          class="q-mr-sm"
          :label="$t('Close')"
          @click="close"
        />

        <q-btn
          color="light-blue-9"
          text-color="white"
          :disable="lockedStates.includes(this.model.state)"
          :label="$t('Save')"
          @click="save"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
function getNewModel () {
  return {}
}

export default {
  name: 'TaskModal',
  emits: ['submit'],
  data () {
    return {
      isOpen: false,
      service: null,
      model: getNewModel(),
      lockedStates: [
        'complete'
      ]
    }
  },
  computed: {
    title () {
      return this.model && this.model.id
        ? `${this.$t('Task')}: ${this.model.id}`
        : this.$t('New task')
    },
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'multiselect',
                label: this.$t('Worker'),
                field: 'executive',
                wrapperStyleClasses: 'col-12 q-pa-xs',
                value: this.model.executive,
                disabled: this.lockedStates.includes(this.model.state),
                hasResetBtn: true,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: []
                  }

                  return this.$service.user.getAll(query)
                },
                onChange: executive => {
                  this.model.executive = executive
                }
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    close () {
      this.isOpen = false
    },
    open (service, model) {
      this.model = model || getNewModel()
      this.service = service
      this.isOpen = true
    },
    save () {
      const data = Object.entries(this.model).reduce((acc, [key, value]) => {
        acc[key] = value && typeof value === 'object' && value.id
          ? value.id
          : value
        return acc
      }, {})

      data.id = undefined

      return this.service.save(data, this.model.id)
        .then(task => {
          this.$emit('submit', task)
          this.close()
        })
    }
  }
}
</script>
