<template>
  <q-tr
    :props="data"
    class="clickable"
    @dblclick="onRowDblClick"
  >
    <q-td
      key="id"
      :props="data"
    >
      {{ data.row.id }}
    </q-td>

    <q-td
      key="state"
      :props="data"
    >
      <super-dropdown :model="schema" />
    </q-td>

    <q-td
      key="queue"
      :props="data"
    >
      <span v-if="data.row._embedded.queue">
        {{ `${data.row._embedded.queue.name} (${data.row._embedded.queue.id})` }}
      </span>
    </q-td>

    <q-td
      key="shop"
      :props="data"
    >
      <span v-if="data.row._embedded.shop">
        {{ `${data.row._embedded.shop.name} (${data.row._embedded.shop.id})` }}
      </span>
    </q-td>

    <q-td
      key="offer"
      :props="data"
    >
      <span v-if="data.row._embedded.productOffer">
        {{ `${data.row._embedded.productOffer.name} (${data.row._embedded.productOffer.id})` }}
      </span>
    </q-td>

    <q-td
      key="order"
      :props="data"
    >
      {{ getOrderID(data.row) || '--' }}
    </q-td>

    <q-td
      key="place"
      :props="data"
    >
      <span v-if="data.row._embedded && data.row._embedded.place">
        {{ `${data.row._embedded.place.room} | ${data.row._embedded.place.row} | ${data.row._embedded.place.rack} | ${data.row._embedded.place.shelf} (${data.row._embedded.place.id})` }}
      </span>
    </q-td>

    <q-td
      key="executive"
      :props="data"
    >
      <span v-if="data.row._embedded && data.row._embedded.executive">
        {{ `${data.row._embedded.executive.username} (${data.row._embedded.executive.id})` }}
      </span>
    </q-td>

    <q-td
      key="created"
      :props="data"
    >
      {{ $moment(data.row.created.date).format(appOptions.formats.date) }}
    </q-td>
  </q-tr>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'

// Components
import SuperDropdown from '../super-dropdown/SuperDropdown.vue'

export default {
  name: 'TaskRow',
  components: {
    SuperDropdown
  },
  props: {
    service: {
      type: Object,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      states: [
        {
          buttons: [
            { id: 'new', title: 'New', transitions: ['rejected', 'canceled'], color: 'lime-5' },
            { id: 'confirmed', title: 'Confirmed', transitions: ['new'], color: 'lime-4' },
            { id: 'complete', title: 'Complete', transitions: [], color: 'lime-3' },
            { id: 'rejected', title: 'Rejected', transitions: ['new'], color: 'red-4' },
            { id: 'canceled', title: 'Canceled', transitions: ['new'], color: 'negative' }
          ]
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ]),
    schema () {
      const value = this.states[0].buttons.find(({ id }) => id === this.data.row.state) || { id: 'new', title: 'New' }

      const option = {
        id: 'statuses',
        type: 'super-dropdown',
        variant: 'link',
        color: value.color || 'default',
        disabledLabels: true,
        editableFields: false,
        onClick: (state) => {
          this.service.save({ state }, this.data.row.id)
            .then(item => {
              this.$emit('submit', item)
            })
        },
        value,
        options: this.states
      }

      return option
    }
  },
  methods: {
    getOrderID (task) {
      if (task._embedded.order) {
        return task._embedded.order.id
      }

      if (task._embedded.reserve && task._embedded.reserve._embedded && task._embedded.reserve._embedded.order) {
        return task._embedded.reserve._embedded.order._links.self.href.split('/').pop()
      }

      return false
    },
    onRowDblClick () {
      this.$emit('dblclick', this.data.row)
    }
  }
}
</script>
